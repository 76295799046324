import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import PageLoader from './components/Common/PageLoader'
import VueElementLoading from "vue-element-loading";
import ServerError from './components/Common/500'
Vue.component("VueElementLoading", VueElementLoading);
import { VueEditor } from "vue2-editor";
Vue.component('VueEditor', VueEditor)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component(VueCropper);
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);
Vue.component('PageLoader', PageLoader)
Vue.component('ServerError', ServerError)
Vue.config.productionTip = false

window.axios = require('axios')
//     axios.defaults.baseURL = 'http://192.168.51.187:5000';
//     Vue.prototype.baseURL = "http://192.168.51.187:5000"
//     Vue.prototype.mediaURL = "http://192.168.51.187:5000/u/"
// Vue.prototype.mediaURLnew = "http://192.168.51.187:5000/wp/"
// Vue.prototype.mediaURLnews = "http://192.168.51.187:5000/file/get/"


// window.axios = require('axios')


axios.defaults.baseURL = 'https://api.storyof1000planters.com/';
Vue.prototype.baseURL = "https://api.storyof1000planters.com/"
Vue.prototype.mediaURL = "https://api.storyof1000planters.com/file/get?key="
Vue.prototype.mediaURLnew = "https://api.storyof1000planters.com/wp?key="


// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.51.59:5051';
// Vue.prototype.baseURL = "http://192.168.51.59:5051"
// Vue.prototype.mediaURL = "http://192.168.51.59:5051/file/get?key="
// Vue.prototype.mediaURLnew = "http://192.168.51.59:5051/wp?key="




new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')