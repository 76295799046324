import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        exe: 0,
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem('userType'),
        userData: {},
        showOrders: false,
    showProducts: false,
    showAddProducts: false,
    showCustomOrders: false,
    currentPage3: 1,
    namespaced: true,

        menus: [],
        initial: "",
        appLoading: false
    },
    mutations: {
        setExe(state, value) {
            state.exe = value;
          },
        setCurrentPage3(state, newPage) {
            state.currentPage3 = newPage;
          },
        setShowOrders(state, value) {
            state.showOrders = value;
          },
          setShowProducts(state, value) {
            state.showProducts = value;
          },
          setShowAddProducts(state, value) {
            state.showAddProducts = value;
          },
          setShowCustomOrders(state, value) {
            state.showCustomOrders = value;
          },
        userData(state, item) {
            state.userData = item
        },
        
 sessionOut(state) {
 localStorage.removeItem("token");
 state.isLoggedIn = false;
 router.push("/"); 
 },
        logoutUser(state) {
            axios({
                method: "GET",
                url: "/user/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";
                    localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        role(state,item){
            state.role = item
        },
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
        },
        activeusersfilter(state,item){
            if(item.userType)
            localStorage.setItem("usertypes",item.userType)
            else
            localStorage.removeItem("usertypes")
            if(item.plan)
            localStorage.setItem("plans",item.plan)
            else
            localStorage.removeItem("plans")
            if(item.from)
            localStorage.setItem("froms",item.from)
            else
            localStorage.removeItem("froms")
            if(item.to)
            localStorage.setItem("tos",item.to)           
            else
            localStorage.removeItem("tos")
            state.activeusersfilter=item
            if(item.currentpage)
            localStorage.setItem("currentpages",item.currentpage)
            localStorage.removeItem("currentpages")
            state.activeusersfilter=item
        }
    },

})