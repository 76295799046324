import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/DashboardPage",
          name: "DashboardPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Dashboard/DashboardPage.vue"),
        },
        {
          path: "/categoryPage",
          name: "categoryPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/category/categoryPage.vue"),
        },

        {
          path: "/Statistics",
          name: "Statistics",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/auctions/Statistics.vue"),
        },
        {
          path: "/BlogsPage",
          name: "BlogsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/blogs/BlogsPage.vue"),
        },


        {
          path: "/CategoryDetailedview",
          name: "CategoryDetailedview",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/category/CategoryDetailedview.vue"),
        },
        {
          path: "/appDashboard",
          name: "appDashboard",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Common/appDashboard"),
        },
        {
          path: "/home",
          name: "home",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/home"),
        },
        {
          path: "/home2",
          name: "home2",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/homeAdd"),
        },
        {
          path: "/NewsPage",
          name: "NewsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/NewsPage"),
        },

        {
          path: "/NewsDetailedView",
          name: "NewsDetailedView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/NewsDetailedView"),
        },
        {
          path: "/LotsPage",
          name: "LotsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/LotsPage"),
        },

        {
          path: "/Upcomminglots",
          name: "Upcomminglots",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/Lots2"),
        },
        {
          path: "/LotDetailedview",
          name: "LotDetailedview",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/LotDetailedview"),
        },

        {
          path: "/AuctionsPage",
          name: "AuctionsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/auctions/AuctionsPage"),
        },

        {
          path: "/DetailedPage",
          name: "DetailedPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/auctions/DetailedPage"),
        },
        {
          path: "/LiveAuctionsPage",
          name: "LiveAuctionsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/auctions/LiveAuctionsPage"),
        },
        {
          path: "/BidsPage",
          name: "BidsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/auctions/BidsPage"),
        },
        {
          path: "/BlogDetailedpage",
          name: "BlogDetailedpage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Home/BlogDetailedpage.vue"),
        },

        {
          path: "/ActiveUsersPage",
          name: "ActiveUsersPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/users/ActiveUsersPage.vue"),
        },

        {
          path: "/FarmersPage",
          name: "FarmersPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/users/FarmersPage.vue"),
        },
        {
          path: "/TradersPage",
          name: "TradersPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/users/TradersPage.vue"),
        },
        {
          path: "/DetailedViewPage",
          name: "DetailedViewPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/users/DetailedViewPage.vue"),
        },

        {
          path: "/storepage",
          name: "storepage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/StorePage.vue"),
        },

        {
          path: "/OrdersPage",
          name: "OrdersPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/OrdersPage.vue"),
        },
        {
          path: "/ProductsPage",
          name: "ProductsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/ProductsPage.vue"),
        },
        {
          path: "/AddProduct",
          name: "AddProduct",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/AddProduct.vue"),
        },
        {
          path: "/CustomOrder",
          name: "CustomOrder",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/CustomOrder.vue"),
        },
        {
          path: "/CustomerOrderView",
          name: "CustomerOrderView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/CustomerOrderView.vue"),
        },

        {
          path: "/StoreDetailedView",
          name: "StoreDetailedView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/store/DetailedView.vue"),
        },
        {
          path: "/BagType",
          name: "BagType",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/bagtype/BagType.vue"),
        },


        {
          path: "/TermsAndConditions",
          name: "TermsAndConditions",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/TermsAndConditions.vue"),
        },

        {
          path: "/ReturnPolicy",
          name: "ReturnPolicy",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/ReturnPolicy.vue"),
        },


        {
          path: "/CancellationPolicy",
          name: "CancellationPolicy",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/CancellationPolicy.vue"),
        },
        {
          path: "/ShippingPolicy",
          name: "ShippingPolicy",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/ShippingPolicy.vue"),
        },
        {
          path: "/RefundPolicy",
          name: "RefundPolicy",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/RefundPolicy.vue"),
        },

        {
          path: "/PrivacyPolicy",
          name: "PrivacyPolicy",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/Policy/PrivacyPolicy.vue"),
        },


        {
          path: "/AnalyticsPage",
          name: "AnalyticsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/analytics/AnalyticsPage.vue"),
        },
        {
          path: "/page1",


          component: () => import("./components/TestPage"),
        },



      ],
    },
    {
      path: "/",
      name: "LoginPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/LoginPage"),
    },
    {
      path: '/OtpPage',
      name: 'OtpPage',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/OtpPage.vue')
      }
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }
  if (store.state.isLoggedIn == true) {
    axios({
      method: "GET",
      url: "/authenticate",
      headers: {
        "token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        // var role = response.data.role;
        // store.commit("role", response.data.role)
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (to.name == "LoginPage" && store.state.isLoggedIn == true) {
    next({ name: "DashboardPage" });
    return;
  }
  next();
});
export default router